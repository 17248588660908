(function () {
  // Detect device type
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    document.querySelector("html").classList.add("mobile");
  } else {
    document.querySelector("html").classList.add("desktop");
  }
})();
